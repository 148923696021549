/*
 * Mixins
 */
$base-font-size: 16px;
@function rem($target) {
	@if $target == 0 { @return 0 }
	@return 1rem * $target / $base-font-size;
}

/*
 * Breakpoints
 */
$breakpoint-grid: #{rem(768px)};

$breakpoint-products-medium: #{rem(768px)};
$breakpoint-products-large : #{rem(1024px)};

/*
 * Colors
 */
$main-color: #f96060;

/*
 * Variables
 */
$gutter: rem(8px);
$animation-popin-duration: .3s;
$animation-slide-duration: .3s;
$animation-appear-duration: .1s;
$animation-disappear-duration: .5s;
$animation-appear-duration: .75s;
$animation-request-button-duration: .1s;