@import "_tools.scss";

/*
 * Buttons styles
 * primary, secondary, square, small
 */
.button {
	display: inline-block;
	padding: rem(8px) rem(20px);
	color: #333;
	font-size: rem(16px);
	font-family: inherit;
	line-height: rem(20px);
	background: #fff;
	border-radius: rem(20px);
	border: rem(2px) solid #444;
	appearance: none;
	cursor: pointer;
	text-decoration: none;
	outline: none;
	user-select: none;

	&:not([disabled]) {
		&:hover {
			color: #c43c3c;
			border-color: #d85959;
		}

		&:focus {
			border-color: #d24e4e;
		}

		&:active {
			transform: translateY(2px);
		}
	}

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

/*
 * Primary
 * (boutons sur fond rouge avec texte blanc)
 */
.button--primary {
	color: #fff;
	border-color: $main-color;
	background: $main-color;

	&:not([disabled]):hover {
		color: #fff;
		border-color: #d24e4e;
		background: #d24e4e;
	}
}

/*
 * Secondary
 * (boutons sur fond blanc, bordure rouge avec texte rouge)
 */
.button--secondary {
	color: #934646;
	border-color: #e48f8f;
	background: #fff;

	&:not([disabled]):hover {
		background: #fff;
	}
}

/*
 * Secondary (No border)
 * (boutons sur fond blanc, sans bordure avec texte rouge)
 */
.button--secondary-noborder {
  color: #934646;
  border-color: #fff;
  background: #fff;

  &:not([disabled]):hover {
    background: #fff;
    border-color: #fff;
  }
}

/*
 * Square
 * (boutons avec bords moins arrondis, limite carrés)
 */
.button--square {
	border-radius: rem(5px);
}

/*
 * Small
 * (petits boutons)
 */
.button--small {
	padding: rem(3px) rem(12px);
	font-size: rem(14px);
}

/*
 * Large
 * (grands boutons)
 */
.button--large {
	padding: rem(8px) rem(20px);
	font-size: rem(18px);
}

/*
 * Link
 * (bouton/lien texte simple)
 */
.button--link {
	display: inline-block;
	padding-top: 0;
	padding-bottom: 0;
	text-decoration: underline;
	border: none;
	background: none;

	&:not([disabled]) {
		&:active {
			transform: none;
		}
	}
}

/* Mini boutons avec flèches */
.button--arrow {
	padding: rem(3px) rem(11px);
	font-family: Arial, sans-serif;
	font-size: rem(10px);
}


/*
 * Alignement / Marges
 * (Marges et alignements)
 */
.button--right {
	float: right;
}

.button--left {
	float: right;
}

.button--mright {
  margin-right: rem(10px)
}

.button--mleft {
  margin-left: rem(10px)
}


/*
 * Groupe de bouton
 * (les boutons seront collés, sans bordures arrondies adjacentes)
 */
.button-group {
	font-size: 0;

	& > *:hover {
		position: relative;
		z-index: 1;
	}

	& > *:not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	& > *:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	& > * + * {
		margin-left: rem(-2px);
	}

	& > *:not(.button) {
		font-size: rem(16px);
	}
}

/*
 * Icônes
 */
[class*="button--icon-"] {
	&:before {
		content: '';
		display: inline-block;
		width: rem(20px);
		height: rem(20px);
		margin-left: rem(-8px);
		margin-right: rem(8px);
		vertical-align: bottom;
		background: url(../../images/common/icons.png) no-repeat 0 0;
		background-size: rem(220px) rem(80px);
	}

	&.button--small:before {
		margin-left: rem(-6px);
		margin-right: rem(2px);
	}

	&.button--primary:before {
		background-position-y: rem(-20px);
	}

	&.button--secondary:before {
		background-position-y: rem(-40px);
	}

	&:not(.button--primary):hover:before {
		background-position-y: rem(-60px);
	}
}

.button--icon {
	/* Parcourir */
	&-browse:before { background-position-x: 0; }

	/* Flèche cochée */
	&-check:before { background-position-x: rem(-20px); }

	/* Croix de fermeture */
	&-cross:before { background-position-x: rem(-40px); }

	/* Personnaliser l'apparence */
	&-customize:before { background-position-x: rem(-60px); }

	/* Noeud */
	&-knot:before { background-position-x: rem(-80px); }

	/* Édition */
	&-pen:before { background-position-x: rem(-100px); }

	/* Plus */
	&-plus:before { background-position-x: rem(-120px); }

	/* Utilisateur */
	&-user:before { background-position-x: rem(-140px); }

	/* Email */
	&-email:before { background-position-x: rem(-160px); }

	/* Flèche */
	&-go:before { background-position-x: rem(-180px); }

	/* Copier */
	&-copy:before { background-position-x: rem(-200px); }
}

/*
 * Loading
 * Spinner, Grisés
 */
.is-loading {
  [class*="button--icon"] {
    &:before {
      background: url(../../images/common/spinner-pink.gif) no-repeat 0 0;
      background-size: rem(20px) rem(20px);
      background-position-x: 0 !important;
      background-position-y: 0 !important;
    }

    &.button--primary:before {
      background: url(../../images/common/spinner-white.gif) no-repeat 0 0;
    }
  }
}


.button.button--loading {
    &:before {
      background-size: rem(20px) rem(20px);
      background-position-x: 0 !important;
      background-position-y: 0 !important;
    }
}

.button.button--loading.spinner--pink {
    &:before {
      background: url(../../images/common/spinner-pink.gif) no-repeat 0 0;
    }
}

.button.button--loading.spinner--white {
    &:before {
      background: url(../../images/common/spinner-white.gif) no-repeat 0 0;
    }
}


/*
 * Buttons Social
 * Facebook, Google
 */
[class*="button--social"] {
  margin-top: rem(10px);

  &:before {
		content: '';
		display: inline-block;
		width: rem(20px);
		height: rem(20px);
		margin-left: rem(-8px);
		margin-right: rem(8px);
		vertical-align: bottom;
		background: url(../../images/common/icons-social.png) no-repeat 0 0;
		background-size: rem(40px) rem(20px);
	}

	@media only screen and (max-width: rem(480px)) {
		font-size: rem(14px);
		max-width: rem(270px);
		padding: rem(8px) rem(10px) rem(8px) rem(15px);
		width: 100%;
	}

	@media only screen and (min-width: rem(481px)) {
		width: rem(310px);
	}
}

.button--social {
	&-google:before { background-position-x: rem(0); }
	&-facebook:before { background-position-x: rem(-20px); }
}


/*
 * Buttons Bookmarklet
 *
 */
[class*="button--bookmarklet"] {
	background: #444;
	border-color: #444;
	border-radius: rem(2px);
	color: #FFF;
	font-size: rem(16px);
	margin: 0 auto;
}

/*
 * Bouton tout rond
 */
.button--bubble {
	min-width: rem(40px);
	padding-left: rem(8px);
	padding-right: rem(8px);

	&[class*="button--icon-"]:before {
		margin: 0;
	}
}
