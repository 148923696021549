@import "_tools.scss";

/*
 * Formulaires
 */
.field {
	margin-bottom: rem(16px);

	label {
		display: block;
		margin-bottom: rem(8px);
	}

  .legend {
		display: inline-block;
		font-size: rem(14px);
  }



	&--with-separator {
		margin-top: rem(30px);
		padding-top: rem(20px);
		border-top: 1px solid #d2d2d2;
	}

	&--hidden {
		display:none;
	}
}

.field--inline {
	margin-bottom: 0;

	label, .textbox, .textarea {
		display: inline-block;
		width: auto;
	}

	label {
		padding-right: 1ch;
	}
}


/* Textbox */
.textbox {
	display: inline-block;
	width: 100%;
	padding: $gutter;
	font-family: inherit;
	font-size: inherit;
	line-height: rem(22px);
	border: rem(1px) solid #afafaf;
	border-radius: rem(5px);
	outline: none;
	appearance: none;

	&:hover {
		border-color: #888;
	}

	&:focus {
		border-color: #ffbfbf;
		box-shadow: inset 0 0 0 rem(1px) #ffbfbf;
	}

	.was-validated &:invalid, .field--error & {
		color: #e81e1e;
		border-color: #ea2d2d;
		background: #fff5f5;
		box-shadow: inset 0 0 0 rem(1px) #ea2d2d;
	}

	&--price {
		max-width: rem(100px);
		text-align: right;
	}

	&--small {
		max-width: rem(180px);
	}

	&--regular {
		max-width: rem(280px);
	}

	&--medium {
		max-width: rem(330px);
	}

	&--large {
		max-width: rem(450px);
	}
}


/* Textarea */
.textarea {
	display: inline-block;
	width: 100%;
	padding: $gutter;
	font-family: inherit;
	font-size: inherit;
	border: rem(1px) solid #afafaf;
	border-radius: rem(5px);
	outline: none;
	appearance: none;

	&:hover {
		border-color: #888;
	}

	&:focus {
		border-color: #ffbfbf;
		box-shadow: inset 0 0 0 rem(1px) #ffbfbf;
	}

	.was-validated &:invalid, .field--error & {
		color: #e81e1e;
		border-color: #ea2d2d;
		background: #fff5f5;
		box-shadow: inset 0 0 0 rem(1px) #ea2d2d;
	}

	&--small {
		max-width: rem(180px);
	}

	&--medium {
		max-width: rem(330px);
	}

  &--large {
		max-width: rem(420px);
	}
}


/* Select */
.select {
	position: relative;
	display: block;
	color: #555;

  &--auto {
    display: inline-block;
  }

  &--small {
		max-width: rem(180px);
	}

	&--medium {
		max-width: rem(330px);
	}
}

	.select select {
		display: inline-block;
		width: 100%;
		margin: 0;
		padding: rem(7px) rem(32px) rem(7px) rem(8px);
		line-height: rem(20px);
		color: #333;
		font-size: rem(16px);
		font-family: inherit;
		background-color: #fff;
		border: 0;
		border-radius: rem(5px);
		border: rem(1px) solid #afafaf;
		cursor: pointer;
		outline: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

    &.border-large {
      border: rem(2px) solid #444;
    }
	}

	.select:after {
		position: absolute;
		top: 50%;
		right: rem(12px);
		display: inline-block;
		content: "";
		width: 0;
		height: 0;
		margin-top: -.15rem;
		pointer-events: none;
		border-top: .35rem solid;
		border-right: .35rem solid transparent;
		border-bottom: .35rem solid transparent;
		border-left: .35rem solid transparent;
	}

	.select select:focus:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #000;
	}

	.select select::-ms-expand {
		display: none;
	}


/* Checkbox */
.checkbox {
  position: relative;

  input[type="checkbox"] {
    //display: none;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
  }

  .checkbox-label {
    display: inline-block;
    line-height: rem(28px);
    padding-left: rem(36px);
    position: relative;
    cursor: pointer;

    &:before {
      border: rem(1px) solid #afafaf;
      border-radius: rem(5px);
      content: '';
      display: inline-block;
      height: rem(26px);
      width: rem(26px);
      position: absolute;
      left: 0;
      top: 0;
      box-sizing: border-box;
      background: #fff;
    }

    &:after {
      color: #000;
      content: '';
      display: block;
      opacity: 0;
      text-align: center;
      width: rem(26px);
      height: rem(26px);
      background: url(../../images/common/checkbox.png) no-repeat center center;
      background-size: cover;

      position: absolute;
      left: 0;
      top: 0;

      transform: scale(0);

      transition: all ease-in-out 0.1s;
    }
  }

  input[type="checkbox"]:checked + .checkbox-label:after {
    opacity: 1;
    transform: scale(1);
  }

  input[type="checkbox"]:focus + .checkbox-label:before {
    border-color: #ffbfbf;
		box-shadow: inset 0 0 0 rem(1px) #ffbfbf;
  }

  &:hover .checkbox-label:before {
    border-color: #888;
  }

	&.checkbox--reverse {
		input {
			float: right;
		}

		.checkbox-label {
			padding-left: 0;
			padding-right: rem(36px);

			&:before,
			&:after {
				left: inherit;
				right: 0;
			}
		}
	}
}

.field--showpassword {
	margin-top: rem(-6px);
}

/* File */
.file {
	position: relative;
	display: inline-block;
	cursor: pointer;
	max-width: 100%;
	min-height: rem(30px);
	margin: 0 auto;

	input[type="file"] {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0;
		max-width: 100%;
	}

	.button {
		position: relative;
		z-index: 1;
		white-space: nowrap;
	}
}

.file--dropzone {
  color: #333;
  font-size: rem(12px);
  line-height: rem(16px);
  padding: rem(5px) 0 rem(75px);
  text-align: center;
  width: 100%;
}


.error {
	display: none;
	margin: rem(8px) 0;
	color: #e81e1e;
}
.field--error .error {
	display: inline-block;
}

.was-validated {
	input, textarea, select {
		&:invalid~.error {
			display: block;
		}
	}
}

.form-required {
  color: #333;
  font-size: rem(13px);
  padding: rem(20px) 0 0 rem(25px);

	@media only screen and (max-width: rem(767px)) {
		padding-bottom: rem(32px);
	}
}

.miel {
	opacity:0;
	position:absolute;
	top:0;
	left:0;
	height:0;
	width:0;
	z-index:-1;
}