@import "_tools.scss";

/*
 * Reset
 */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	color: #333;
	font: 1em/1.5 'Work Sans', sans-serif;
	background: #fff;
	min-width: rem(320px);
	position: relative;
	overflow-x: hidden;
}

a {
	color: inherit;

	&:hover {
		color: #c33a3a;
	}
}

img {
	border: 0;
}

b, strong {
	font-weight: 600;
}

p {
	margin-bottom: 1.5em;

	&:last-child {
		margin-bottom: 0;
	}

	&.align-center {
		text-align: center;
	}
}

.fs12 { font-size: rem(12px); }
.fs14 { font-size: rem(14px); }
.fs16 { font-size: rem(16px); }

ul.list-bullet {
	list-style-type: none;

	li:before {
		content: '•';
		display: inline-block;
		margin-right: rem(8px);
	}
}

textarea {
	resize: vertical;
	height: 5em;
	min-height: 2.5em;
}

@import "_fonts.scss";

/*
 * Common
 */
 .site {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	background: #ffebdd;
	background: #ffebdd url(../../images/common/bg.svg) repeat-y top center / 100% auto;
	position: relative;

	&:after {
		position: absolute;
		bottom: 0;
    content: '';
    display: block;
    height: rem(100px);
    background: linear-gradient(to bottom, rgba(255,255,255,0), #FFF);
	}
 }

.page {
	//flex: 1;
	padding: rem(48px) 0;

	@media only screen and (max-width: rem(600px)) {
		padding: calc(#{((48 + 32) * 100 / 600) * 1vw} - #{rem(32px)}) 0;
	}

	@media only screen and (max-width: rem(320px)) {
		padding: rem(8px) 0;
	}
}

.content {
	position: relative;
	max-width: rem(1216px);
	margin: 0 auto;
	padding: 0 #{$gutter * 2};
}

/*
 * Loading screen
 */
.loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.loader {
	display: block;
	margin: rem(10px) auto;
	width: rem(10px);
	height: rem(10px);
	background: #f96060;
	border-radius: rem(5px);
	animation: loader-pulse ease-in-out 3s infinite;
}

	@keyframes loader-pulse {
		from { transform: scale(0.5); }
		50% { transform: scale(1); }
		to { transform: scale(0.5); }
	}

.hidden {
	display:none;
}

/*
 * Box
 */
.box {
	padding: rem(24px);
	background: #fff;
	border-radius: rem(10px);
	box-shadow:0 1px 3px 0 rgba(0,0,0,0.3);

	&--invisible {
		background: none;
		box-shadow: none;

		@media only screen and (max-width: rem(767px)) {
			padding-left: 0;
		}
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}

/*
 * Grid
 * (grille principale du site, avec deux colonnes en desktop et gouttière)
 */
.grid {
	padding: 0 $gutter;
	margin: 0 #{$gutter * -2};

	@media only screen and (min-width: $breakpoint-grid) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		list-style: none;
		align-items: flex-start;
		align-content: flex-start;

		&--same-height {
			align-items: stretch;
			align-content: stretch;

			& > * {
				display: flex;
			}
		}
	}

	& > * {
		margin: $gutter;
		flex: 1 1 auto;

		@media only screen and (min-width: $breakpoint-grid) {
			width: calc(50% - #{$gutter * 2});
			flex-grow: 0;
		}
	}
}

/*
 * Grid-products
 * (grille utilisée pour le listing produits, avec trois/quatre colonnes en desktop et gouttières)
 */
.grid-products {
	list-style: none !important;
	margin: 0 #{$gutter * -2};
	padding: 0 $gutter;

	@media only screen and (min-width: $breakpoint-grid) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		list-style: none;
		align-items: flex-start;
		align-content: flex-start;

		&--same-height {
			align-items: stretch;
			align-content: stretch;

			& > * {
				display: flex;
			}
		}
	}

	& > * {
		margin: $gutter;
		flex: 1 1 auto;

		@media only screen and (min-width: $breakpoint-products-medium) {
			width: calc(50% - #{$gutter * 2});
			flex-grow: 0;
		}

		@media only screen and (min-width: $breakpoint-products-large) {
			width: calc(33.33% - #{$gutter * 2});
			flex-grow: 0;
		}

	}
}

.grid--header {
	margin-bottom: rem(20px);

	@media only screen and (max-width: rem(600px)) {
		margin-bottom: calc(#{((12 + 16) * 100 / 600) * 1vw} - #{rem(8px)});
	}

	@media only screen and (max-width: rem(320px)) {
		margin-bottom: rem(8px);
	}
}

/*
 * Row & Column
 * (système de colonnes génériques)
 */
.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: space-between;
}

.column {
	flex: 0 1 auto;
}

/*
 * Header
 */
.header {
	background: #fefaf7;
	position: relative;
}

.navbar {
	align-items: center;
  	background: #fefaf7;
	display: flex;
	flex-direction: row;
	height: auto;
	justify-content: space-between;
	min-height: rem(70px);

	@media only screen and (max-width: rem(767px)) {
		flex-direction: column;
	}
}

.logo {
	img {
		display: block;
		width: rem(150px);
		height: rem(37px);
	}

  @media only screen and (max-width: rem(767px)) {
    display: block;
		margin-top: rem(10px);

		img {
			width: rem(150px);
			height: auto;
		}
  }
}

.nav {
	align-items: center;
	background: #fefaf7;
	color: #010101;
	display: flex;
	font-size: rem(14px);
	height: rem(70px);
	justify-content: flex-end;
	text-align: right;

  .nav-logout {
    margin-left: rem(25px);
  }

  @media only screen and (max-width: rem(768px)) {
		height: rem(40px);
		justify-content: center;

		&.is-connected {
			height: rem(50px)
		}
  }
}

/*
 * Banner
 */
.header:after {
	content: '';
	display: block;
	height: rem(190px);
	background: #f96060 url(../../images/common/banner-1024w.jpg) no-repeat center center;
	border-bottom: rem(4px) solid $main-color;

	@media only screen and (max-width: rem(480px)) {
		background-image: url(../../images/common/banner-480w.jpg);
	}

	@media only screen and (min-width: rem(1024px)) {
		background-image: url(../../images/common/banner-2048w.jpg);
	}
}

/*
 * Footer
 */
.footer {
	padding-bottom: rem(75px);
	text-align: center;

	a {
		font-size: rem(14px);
		margin: 0 rem(24px);
		white-space: nowrap;

		@media only screen and (max-width: rem(600px)) {
			display: inline-block;
			margin: 0 rem(8px);
		}
	}

	.footer-tilt {
		display: inline-block;
		margin: rem(80px) auto;
	}
}

/*
 * Page
 */
.page-title {
	margin-top: rem(8px);
	margin-bottom: rem(16px);
	color: $main-color;
	font-size: rem(36px);
	font-weight: normal;
	line-height: rem(40px);
}

.page-subtitle {
	margin-bottom: rem(16px);
	color: #333;
	font-size: rem(20px);
	font-weight: normal;
	line-height: 1.3;

	&.page-subtitle--aligntop {
		margin-top: rem(-24px);
	}
}

/* Illustrations de bébés */
.baby {
	display: block;
	margin-top: rem(48px);
	max-width: 100%;
	height: auto;
}

	.baby--right {
		float: right;
	}

	.baby--keys {
		float: right;
		margin-top: 0;
	}

	.baby-keys + p {
		min-width: rem(256px);
	}

	.baby--share {
		margin: rem(80px) 0 0 rem(40px);
	}


/*
 * Page Bookmarklet
 */
.bookmarklet--help-favs {
	display: inline-block;
	margin-right: rem(8px);
}

.bookmarklet--help-list {
	display: inline-block;
	list-style-type: none;
	vertical-align: top;
	margin-bottom: rem(16px);
}

.bookmarklet--help-demo,
.bookmarklet--help-demo-shown {
	display: block;
	position: relative;
}

.bookmarklet--help-demo:before {
	background: url(../../images/bookmarklet/play.png) no-repeat center center;
	border-radius: 50%;
	box-shadow: 0 0 rem(4px) rem(3px) rgba(131, 107, 90, .75);
	content: '';
	display: block;
	height: rem(73px);
	left: calc(50% - #{rem(36px)});
	position: absolute;
	top: calc(50% - #{rem(36px)});
	transform: scale(.95);
	transition: transform .2s ease-in-out;
	width: rem(73px);
}

.bookmarklet--help-demo:after {
	content: 'Voir la démonstration';
	color: #000;
	text-decoration: underline;
	left: 0;
	right: 0;
	position: absolute;
	top: calc(50% + #{rem(40px)});
	text-align: center;
}

.bookmarklet--help-demo:hover:before {
	transform: scale(1);
}

.bookmarklet--help-demo img,
.bookmarklet--help-demo-shown img {
	border: rem(1px) solid #ccc;
	height: auto;
	width: 100%;
}

/*
 * Notifications
 */
.notification {
	margin-bottom: rem(48px);
	padding: rem(20px);
	font-size: rem(16px);
	border: 2px solid #d5beae;
	border-radius: rem(10px);
	background: #fff;
}

	.notification--error {
		color: #e81e1e;
		border-color: #ea2d2d;
		background: #fff5f5;
	}

/*
 * Mot de passe oublié
 */
.content--forgotten-password {
	@media only screen and (max-width: rem(480px)) {
		.box.box--invisible {
			display: flex;
			flex-direction: column;
			padding-right: 0;

			p {
				order: 1;
			}

			.baby {
				float: inherit;
				margin: 0 auto;
				order: 2;
			}
		}
	}
}


/*
 * A Propos
 */
.content--about {
	.box:not(.box--invisible) {
		font-size: 0;

		img {
			border: rem(1px) solid #ccc;
			height: auto;
			width: 100%;
		}
	}
}


/*
 * Page 404
 */
.content--404 {
	padding: 0;
	width: 100%;
	max-width: inherit;

	.box {
		text-align: center;

		p {
			font-size: rem(16px);
			margin: rem(30px) auto;
		}
	}

	&:after {
		background: url(../../images/404/404.png) no-repeat left bottom;
		content: '';
		display: block;
		height: rem(271px);
		margin: rem(30px) 0 rem(50px) calc(50% - #{rem(30px)});
		width: rem(1202px);
		max-width: calc(50% + #{rem(30px)});
	}
}

@import "_forms.scss";


@import "_buttons.scss";
